.icon-list {
    margin-top: 4px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
    width: 100%;
    
    .icon-button {
        padding: 6px 0;
        border-radius: 8px;
        border: none;
        background: #f1f1f1;
        cursor: pointer;
        transition: transform 0.2s;
        &:focus {
            outline: none;
        }
        &:hover {
            transform: scale(1.1);
        }

        &.sw{
            color: #0FE367;
            &.active{
                color:#FFF;
                background-color: #0FE367;
            }
        }
        &.erotic{
            color: #FFCC00;
            &.active{
                color:#FFF;
                background-color: #FFCC00;
            }
        }
        &.explicit{
            color: #FF9500;
            &.active{
                color:#FFF;
                background-color: #FF9500;
            }
        }
        &.extrem{
            color: #FF3636;
            &.active{
                color:#FFF;
                background-color: #FF3636;
            }
        }
    }
}

@media screen and (max-width: 720px) { 
    .content-card-footer {
        flex-direction: column;
    }
}