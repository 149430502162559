#facette-form{
  .infos{
    display: flex;
    flex-wrap: wrap;
    //flex-direction: column;
    .title{
      display: flex;
      justify-content: center;
      font-size: .9rem;
      background-color: rgba(51,51,51,.1);
      padding: 0.25rem 0.5rem;
      border-radius: 3px;
    }
    .text-info{
      color : #2276f4;
    }
    .text-danger{
      color : #dd0505;
    }
    .text-warning{
      color : #db8100;
    }
    .text-underlined{
      text-decoration: underline;
    }
  }
}