.addItem {
    background: #FFF;
    display: inline-block;
    padding: 4px 8px;

    .menu-item{
        display: flex;
        align-items: center;
        padding: 8px;
        color: $color-text-default;
        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;
        transition: all .1s linear;
        i {
            width: 14px;
            margin-right: 16px;
            fill: darken($color-grey-light, 20);
            transition: all .1s linear;
        }
    }

    .menu-item-color{
        width: 20px;
        height: 20px;
        border-radius: $border-radius-rounded;
        margin: 0 6px;
        cursor: pointer;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &.color-primary{
            background: $color-primary;
        }
        &.color-secondary{
            background: $color-text-grey-medium;
        }
        &.color-success{
            background: $color-green;
        }
        &.color-danger{
            background: $color-red;
        }
        &.color-warning{
            background: $color-orange;
        }
        &.color-info{
            background: lighten($color-primary, 15);
        }
        &:hover, &.active{
            &.color-primary{
                border-color: darken($color-primary, 20);
            }
            &.color-secondary{
                border-color: darken($color-text-grey-medium, 20);
            }
            &.color-success{
                border-color: darken($color-green, 20);
            }
            &.color-danger{
                border-color: darken($color-red, 20);
            }
            &.color-warning{
                border-color: darken($color-orange, 20);
            }
            &.color-info{
                border-color: darken($color-primary, 20);
            }
        }
    }

    .menu-item-color__active{
        height: 0;
        width: 0;
        margin: auto;
        position: absolute;
        border-radius: $border-radius-rounded;
        opacity: .5;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        &:hover, &.active{
            height: 24px;
            width: 24px;
        }
    }

    .addItemPopover {
        margin-top: 10px;
        background: #FFF;
        position: absolute;
        //height: 54px;
        width: 300px;
        border-radius: 2px;
        padding: 10px;
        box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
        z-index: 1000;
        &.colorPopover{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: auto;
            max-width: 300px;
        }
    }

    .addItemClosedPopover {
        display: none;
    }

    .addItemButton {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        background: #fff;
        padding: 0;
        color: #888;
        margin: 0;
        cursor: pointer;
        line-height: 1.2em;
        height: 2em;
        width: 2em;
        margin: 0;
        border-radius: $border-radius-rounded;
        i{
            width:  16px;
        }

        &.addItemButton:focus {
            outline: 0; /* reset for :focus */
        }

        &:hover {
            background: #f3f3f3;
        }

        &:active {
            background: #f3f3f3;
        }
        &.active{
            background-color: #f3f3f3;
        }
        &.color-primary{
            i{
                fill: $color-primary;
            }
        }
        &.color-secondary{
            i{
                fill: $color-text-grey-medium;
            }
        }
        &.color-success{
            i{
                fill: $color-green;
            }
        }
        &.color-danger{
            i{
                fill: $color-red;
            }
        }
        &.color-warning{
            i{
                fill: $color-orange;
            }
        }
        &.color-info{
            i{
                fill: lighten($color-primary, 15);
            }
        }
        .selectedColor{
            height: 2px;
            width: 100%;
            background-color: #000;
        }
    }

    .addItemPressedButton {
        @extend .addItemButton;
        background: #f3f3f3;
    }

    .addItemBottomGradient {
        width: 100%;
        height: 1em;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: white;
        pointer-events: none;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }

    .addItemInput {
        box-sizing: border-box;
        border: 1px solid #ddd;
        cursor: text;
        padding: 4px;
        width: 78%;
        border-radius: 2px;
        box-shadow: inset 0px 1px 8px -3px #ABABAB;
        background: #fefefe;
    }

    .addItemConfirmButton {
        box-sizing: border-box;
        background: #fff;
        border: 1px solid #ddd;
        padding: 0;
        color: #888;
        margin: 0;
        border-radius: 2.1em;
        cursor: pointer;
        height: 2.1em;
        width: 18%;
        font-size: 1em;
        line-height: 2.1em;
        margin: 0;
        margin-left: 4%;

        &.addItemConfirmButton:focus {
            outline: 0; /* reset for :focus */
        }

        &.addItemConfirmButton:hover {
            background: #f3f3f3;
        }

        &.addItemConfirmButton:active {
            background: #e6e6e6;
        }
    }
    &.active{
        @extend .addItemPressedButton;
    }
    &.chat-add-img{
        all: unset;
        display: flex;
        .menu-item{
            i{
                margin-right: 0;
            }
        }
    }
}