#members{
  .risk{
    background-color: #dc3545!important;
    color: #FFFFFF;
    a{
      color: #FFFFFF;
    }
    .btn-outline-primary{
      border-color: #FFFFFF;
    }
  }
  .panic-mode{
    background-color: #ffc107!important;
    .btn-outline-success{
      border-color: #4A4A4A !important;
    }
  }
  .action-panic{
    min-width: 100px;
  }
  .legends{
    margin-bottom: 16px;
    .legend-members{
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      li{
        margin: 0 8px;
        padding: 4px;
      }

    }
  }

}