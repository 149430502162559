.content-history {
    padding: 20px;
    font-family: Arial, sans-serif;

    &__loading {
        text-align: center;
        font-size: 16px;
        color: #666;
    }

    &__empty {
        text-align: center;
        font-size: 14px;
        color: #999;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__item {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 10px 15px;
        background: #f9f9f9;

        &:hover {
            background: #f4f4f4;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;

            .content-history__username {
                font-weight: bold;
                color: #007bff;
            }

            .content-history__action {
                font-size: 14px;
                color: #333;
                font-weight: bold;
            }

            .content-history__date {
                font-size: 12px;
                color: #666;
            }
        }

        &__details {
            font-size: 14px;
            color: #555;

            p {
                margin: 5px 0;
                b {
                    color: #333;
                }
            }
        }
    }
}
