.content-rating-video {
    display: flex;
    flex-direction: column;
    gap: 16px; // Espacement entre chaque carte
    width: 100%;
  
    .content-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start; // Aligner le contenu à gauche
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #ddd;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 8px;
      background: #fff;

      &.active{
        // border: #313fff solid 3px;
        box-shadow: 0 0 0px 3px #313fff;
    }

    .info-button {
      background: rgba(35, 35, 35, 0.8);
      color: #FFF;
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      &:focus {
          outline: none;
      }
  }

      .content-card-footer{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 8px;
        align-items: center;
        
        .footer-icon-list{
            width: 200px;
            justify-content: flex-end;
            display: flex;

            .content-rating-button {
              color: #fff;
              border: none;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: default;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
              &.sw{
                  background: #0FE367;
              }
              &.erotic{
                  background: #FFCC00;
              }
              &.explicit{
                  background: #FF9500;
              }
              &.extrem{
                  background: #FF3636;
              }
          }
        }
      }
  
      .content-rating-video-title {
        font-size: 14px;
        font-weight: bold;
        
      }

      .content-rating-video-duration {
        font-size: 12px;
        color: #505050;
      }

      .content-container{
        display: flex;
      }

      .content-container-video{
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .video-container {
        width: 100%;
        max-height: 400px; /* Limite la hauteur maximale */
        overflow: hidden; /* Masque les débordements */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .video-player video {
        max-height: 100%; /* Respecte la hauteur maximale du conteneur */
        width: auto; /* Garde les proportions */
        object-fit: contain; /* Centre la vidéo et ajuste pour ne pas déformer */
      }
      
      

        .images-container-scroll {
          height: 170px;
          background-color: #ddd;
          overflow-x: scroll;
          display: flex;
          gap: 8px; // Espacement entre les images
          .image-container {
            width: 150px;
            height: 150px;
            background-color: #ddd;
            
            min-width: 150px;
            min-height: 150px;
    
            img {
              width: 100%;
              height: 100%;
              object-fit: cover; // Assure un bon affichage des images
              
            }
    
          }
        }

        .images-container {
        display: flex; // Images alignées horizontalement
        flex-wrap: wrap; // Permet un retour à la ligne si nécessaire
        gap: 8px; // Espacement entre les images
        justify-content: center;
        flex: 1;


        .image-container {
          width: 150px;
          height: 150px;
          background-color: #ddd;
          position: relative;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover; // Assure un bon affichage des images
          }
  
          .player-button {
            position: absolute;
            top: 50px;
            right: 50px;
            background: rgba(35, 35, 35, 0.8);
            color: #fff;
            border: none;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            font-size: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            z-index: 2;
            &:focus {
                outline: none;
            }
          }
        }
      }
      }
    }
  