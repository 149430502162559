.autocomplete-user {
    position: relative;
    width: 100%;
    font-family: Arial, sans-serif;

    .input-container {
        display: flex;
        align-items: center;
        position: relative;

        .autocomplete-input {
            width: 100%;
            padding: 8px 12px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 14px;
            transition: border 0.3s ease;

            &:focus {
                border-color: #007bff;
                outline: none;
            }

            &:disabled {
                background-color: #f5f5f5;
                color: #aaa;
            }
        }

        .reset-button {
            position: absolute;
            right: 5px;
            background: none;
            border: none;
            color: #888;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                color: #333;
            }
        }

        .loading-spinner {
            position: absolute;
            right: 10px;
            font-size: 14px;
            color: #888;
        }
    }

    .suggestions-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin-top: 4px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        max-height: 200px;
        overflow-y: auto;
        z-index: 1000;
        list-style: none;
        padding: 0;

        .suggestion-item {
            padding: 8px 12px;
            cursor: pointer;

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
}
