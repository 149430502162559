#keywords-warnings{
  .warning-text{
    width: 500px;
  }
  .actions {
    width: 160px;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .action {
    margin-bottom: 5px;
  }
  .keyword-warning{
    font-weight: bold;
    text-decoration-line: underline;
    background: yellow;
  }

  .content-remove{
    text-decoration: line-through;
  }

  .ellipsis {
    opacity: .8;
    cursor: pointer; /* pour les vieux navigateurs */
  }
  .ellipsis:not(.visible) {
    cursor: zoom-in;
  }
  .ellipsis:not(.visible)::before {
    content: '[...]';
  }
  .ellipsis.visible {
    cursor: zoom-out;
  }
}