.mentionSuggestions {
  background: #fff;
  border-radius: $border-radius;
  cursor: pointer;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  overflow: auto;

  .mentionSuggestionsEntryContainer {
    display: flex;
    align-items: center;
  }

  .mentionSuggestionsEntryContainerRight {
    width: 100%;
    padding-left: 8px;
  }

  .mentionSuggestionsEntry {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: $border-radius;
    &:active {
      //background-color: #cce7ff;
      background-color: $color-grey-light;
    }
  }


  .mentionSuggestionsEntryFocused {
    @extend .mentionSuggestionsEntry;
    //background-color: #e6f3ff;
    background-color: $color-grey-light;
  }

  .mentionSuggestionsEntryText,
  .mentionSuggestionsEntryTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
  }

  .mentionSuggestionsEntryTitle {
    color: $color-text-default;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }
}

.mentionned-name{
  color: $color-primary;
  font-weight: 600;
}