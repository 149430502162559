#banned-members{
  .last-domain{
    list-style: none;
    display: flex;
    max-width: 100%;
    overflow: auto;
    li{
      padding: 8px;
      min-width: fit-content;
    }
  }

  .banned-members-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .banned-members-list-item{
      width: 48%;
      border: #6F6F6F 1px solid;
      margin: 1%;
      padding: 8px;

      .banned-members-list-item-informations{
        display: flex;
        justify-content: space-between;
        border-bottom: #9e9e9e 0.5px solid;
      }
      .banned-members-list-item-content{
        padding-top: 8px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  #banned-members{
    .banned-members-list{

      .banned-members-list-item {
        width: 100%;
        margin: 0 0 16px 0;
        .banned-members-list-item-informations{
          flex-direction: column;
          padding-bottom: 8px;
        }
      }
    }
  }
}