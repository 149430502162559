// SINGLE ELEMENT SPINNER

.loader {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 2px solid darken(#E9E9E9, 15);
  border-radius: 50%;
  animation: spin 0.75s infinite linear;

  &::before,
  &::after {
    left: -2px;
    top: -2px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
  }
  &.xsmall {
    width: 10px;
    height: 10px;
  }

  &.small {
    width: 20px;
    height: 20px;
  }

  &.big {
    width: 60px;
    height: 60px;
    margin: 64px auto;
  }
}

.spin-loader,
.spin-loader::before {
  display: block;
  border-color: transparent;
  border-top-color: darken(#E9E9E9, 15);
}

.spin-loader::before {
  height: 100%;
  width: 100%;
  animation: spin 1.5s infinite ease;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.infinite-loading-container{
  position: relative;
  .infinite-loading-bar {
    height: 4px;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background-color: #E9E9E9;
    border-radius: 2px;
    &::before{
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 4px;
      background-color: #2276f4;
      animation: infinite-loading-bar 2s linear infinite;
    }
  }
}

@keyframes infinite-loading-bar {
  from {left: -200px; width: 20%;}
  90% {width: 70%;}
  80% { left: 80%;}
  95% {left: 120%;}
  to {left: 100%;}
}