#facette-categories{
    .list-group .list-group-item {
        font-size: 0px;
    }
    .list-group .cursor-pointer {
        font-size: 16px;
        position: relative;
    }
    .list-group .list-group .cursor-pointer::before {
        content: "";
        border-top: solid 1px black;
        width: 17px;
        height: 1px;
        display: block;
        position: absolute;
        left: -20px;
        top: 12px;
    }
    .list-group [role="tab"] [role="tab"] {
        border-left: solid 2px black;
    }

    .list-group-item {
        border-top: none !important;
        border-bottom: none !important;
        padding: 2px 0 2px 16px;
    }
    .list-group [type="button"] {
        margin-top: 1px !important;
        font-size: 10px;
        padding: 1px 6px;
    }
}