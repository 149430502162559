.readonly-editor{
  img, video{
    max-width: 100%;
    max-height: 1000px;
  }
  .draft-uploaded-media{
    width: 100%;
    img, video{
      max-width: 100%;
      max-height: 1000px;
    }
  }

  .quoting-user{
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    width: 100%;
    border-left: 10px solid #ccc;
    padding: 16px;
    border-radius: $border-radius;
    .blockquote--classic{
      all: unset;
      border-left: 0;
      padding-left: 0;
      font-style: italic;
    }
    .quoting-user-username{
      margin-left: auto;
      font-size: 14px;
    }
  }

  .draft-shared-media{
    display: flex;
    flex-direction: column;
    width: 250px;
    padding: 16px;
    text-decoration: none;
    border: 1px solid $color-grey-light;
    border-radius: $border-radius;
    .content{
      overflow: hidden;
      max-width: 100%;
      max-height: 200px;
      min-height: 200px;
      margin-bottom: 8px;
      overflow: hidden;
      border-radius: $border-radius;
      background-color: $color-grey-light;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      img{
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }
    }
    .caption{
      border-top: 1px solid $color-grey-light;
      padding-top: 8px;
      font-weight: 600;
      @extend .break-long-text;
      color: $color-text-default;
    }
    &.article, &.album{
      .content{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        flex-grow: 1;
      }
    }
    &.error{
      font-size: 14px;
      color: $color-text-grey-medium;
      text-align: center;
    }
  }

  .link{
    color: $color-primary;
  }

  .mentionned-name{
    color: inherit;
  }

  span[data-text]{
    @extend .break-long-text;
    font-family: $font-for-readability;
  }

  .emojiSelectPopoverGroups{
    width: unset;
  }
  .emojiSelectPopoverGroupItem{
    flex: 0 0 calc(100% / 7);
  }
  .DraftEditor-root{
    .public-DraftEditor-content{
      max-height: unset;
      min-height: unset;
      h1{
        font-size: 2em;
      }
      h2{
        font-size: 1.5em;
      }
      h3{
        font-size: 1.17em;
      }
      h4{
        font-size: 1em;
      }
      h5{
        font-size: 0.83em;
      }
      h6{
        font-size: 0.67em;
      }
    }
  }
}