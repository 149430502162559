.mk-editor{
  .color-primary{
    color: $color-primary;
  }
  .color-secondary{
    color: $color-text-grey-medium;
  }
  .color-success{
    color: $color-green;
  }
  .color-danger{
    color: $color-red;
  }
  .color-warning{
    color: $color-orange;
  }
  .color-info{
    color: lighten($color-primary, 15);
  }

  .quoting-user{
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    width: 100%;
    border-left: 10px solid #ccc;
    padding: 16px;
    border-radius: $border-radius;
    .blockquote--classic{
      all: unset;
      border-left: 0;
      padding-left: 0;
      font-style: italic;
    }
    .quoting-user-username{
      margin-left: auto;
      font-size: 14px;
    }
  }

  .draft-survey{
    max-width: 500px;
    margin: 0 auto;
    padding: 16px;
    border: 1px solid $color-grey-light;
    border-radius: $border-radius;
    .survey{
      border: 0;
      padding: 0;
      margin: 0;
    }
  }
}