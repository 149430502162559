#status{
  .status-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .status-list-item{
      width: 48%;
      border: #6F6F6F 1px solid;
      margin: 1%;
      padding: 8px;

      .status-list-item-informations{
        display: flex;
        justify-content: space-between;
        border-bottom: #9e9e9e 0.5px solid;
      }
      .status-list-item-content{
        padding-top: 8px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  #status{
    .status-list{

      .status-list-item {
        width: 100%;
        margin: 0 0 16px 0;
        .status-list-item-informations{
          flex-direction: column;
          padding-bottom: 8px;
        }
      }
    }
  }
}