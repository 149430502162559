#content-rating{
    .content-rating-header {
        margin-bottom: 16px;
        .content-rating-header-filter {
            padding: 16px;  
            background-color: #f9f9f9;
            border-bottom: 1px solid #ddd;
            border-radius: 16px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px; // Espace entre les groupes
    
            .content-features-header-filter-separation{
                border: #e4e4e4 solid 0.5px;
            }
            .filter-right{
                display: flex;
                justify-content: flex-end;
                flex: 1 1;
                align-items: center;
                .filter-submit{
                    background: #007bff;
                    color : #FFF;
                    border: 0;
                    border-radius: 8px;
                    padding: 8px 12px;
                    transition: transform 0.2s;
                    &:hover{
                        transform: scale(1.02);
                        background: #2990ff;
                    }
                    &:disabled{
                        background: #dddddd;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
            .content-rating-header-filter-input-group {
                display: flex;
                gap: 4px; // Espace entre label et select
                align-items: center;
                .filter-select {
                    padding: 8px 12px;
                    font-size: 14px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    background-color: #fff;
                    cursor: pointer;
                    width: 200px; // Taille fixe pour un alignement cohérent
    
                    &:focus {
                        outline: none;
                        border-color: #007bff;
                        box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
                    }
                }
            }
        }
    }
    .content-rating-footer{
        display: flex;
        justify-content: flex-end;
        margin: 20px 0px;
        .content-submit{
            background: #007bff;
            color : #FFF;
            border: 0;
            border-radius: 8px;
            padding: 8px 12px;
            transition: transform 0.2s;
            &:hover{
                transform: scale(1.02);
                background: #2990ff;
            }
            &:disabled{
                background: #dddddd;
            }
        }
    }
    
}
