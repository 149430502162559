@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800);
.AnnouncementMessagesEditor_announcement-messages-editor__1iHa8 .AnnouncementMessagesEditor_editor-container__Co5i8 {
  flex: 1 1;
  position: relative;
  border-radius: 10px;
  background: #f3f3f3;
  padding-right: 30px; }

.AnnouncementMessagesEditor_announcement-messages-editor__1iHa8 .AnnouncementMessagesEditor_editor-wrapper__wn_R4 {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background-color: #FFF; }

.AnnouncementMessagesEditor_announcement-messages-editor__1iHa8 .AnnouncementMessagesEditor_editor-input__1a9DK {
  padding: 10px 14px;
  outline: 0;
  font-size: 14px;
  line-height: 1.15em;
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto; }

.AnnouncementMessagesEditor_announcement-messages-editor__1iHa8 .AnnouncementMessagesEditor_editor-placeholder__3T8QF {
  color: #999;
  position: absolute;
  align-items: center;
  overflow: visible;
  top: 11px;
  left: 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  font-size: 14px; }

#content-rating .content-rating-header {
  margin-bottom: 16px; }
  #content-rating .content-rating-header .content-rating-header-filter {
    padding: 16px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    border-radius: 16px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px; }
    #content-rating .content-rating-header .content-rating-header-filter .content-features-header-filter-separation {
      border: #e4e4e4 solid 0.5px; }
    #content-rating .content-rating-header .content-rating-header-filter .filter-right {
      display: flex;
      justify-content: flex-end;
      flex: 1 1;
      align-items: center; }
      #content-rating .content-rating-header .content-rating-header-filter .filter-right .filter-submit {
        background: #007bff;
        color: #FFF;
        border: 0;
        border-radius: 8px;
        padding: 8px 12px;
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s; }
        #content-rating .content-rating-header .content-rating-header-filter .filter-right .filter-submit:hover {
          -webkit-transform: scale(1.02);
                  transform: scale(1.02);
          background: #2990ff; }
        #content-rating .content-rating-header .content-rating-header-filter .filter-right .filter-submit:disabled {
          background: #dddddd; }
        #content-rating .content-rating-header .content-rating-header-filter .filter-right .filter-submit:focus {
          outline: none; }
    #content-rating .content-rating-header .content-rating-header-filter .content-rating-header-filter-input-group {
      display: flex;
      grid-gap: 4px;
      gap: 4px;
      align-items: center; }
      #content-rating .content-rating-header .content-rating-header-filter .content-rating-header-filter-input-group .filter-select {
        padding: 8px 12px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        width: 200px; }
        #content-rating .content-rating-header .content-rating-header-filter .content-rating-header-filter-input-group .filter-select:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 4px rgba(0, 123, 255, 0.4); }

#content-rating .content-rating-footer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px; }
  #content-rating .content-rating-footer .content-submit {
    background: #007bff;
    color: #FFF;
    border: 0;
    border-radius: 8px;
    padding: 8px 12px;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
    #content-rating .content-rating-footer .content-submit:hover {
      -webkit-transform: scale(1.02);
              transform: scale(1.02);
      background: #2990ff; }
    #content-rating .content-rating-footer .content-submit:disabled {
      background: #dddddd; }

.content-rating-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 12px;
  gap: 12px;
  justify-content: center; }
  .content-rating-grid .content-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: auto;
    border: #ddd solid 0.5px;
    padding: 4px; }
    .content-rating-grid .content-card.active {
      box-shadow: 0 0 0px 3px #313fff; }
  .content-rating-grid .image-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    background: #000;
    border-radius: 10px; }
    .content-rating-grid .image-container .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .content-rating-grid .image-container .fullscreen-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: rgba(35, 35, 35, 0.8);
      color: #FFF;
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
      .content-rating-grid .image-container .fullscreen-button:focus {
        outline: none; }
    .content-rating-grid .image-container .info-button {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: rgba(35, 35, 35, 0.8);
      color: #FFF;
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
      .content-rating-grid .image-container .info-button:focus {
        outline: none; }
    .content-rating-grid .image-container .content-rating-button {
      position: absolute;
      top: 10px;
      left: 10px;
      color: #fff;
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
      .content-rating-grid .image-container .content-rating-button.sw {
        background: #0FE367; }
      .content-rating-grid .image-container .content-rating-button.erotic {
        background: #FFCC00; }
      .content-rating-grid .image-container .content-rating-button.explicit {
        background: #FF9500; }
      .content-rating-grid .image-container .content-rating-button.extrem {
        background: #FF3636; }

@media screen and (max-width: 1000px) {
  .content-rating-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); } }

@media screen and (max-width: 720px) {
  .content-rating-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); } }

.icon-list {
  margin-top: 4px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4px;
  gap: 4px;
  width: 100%; }
  .icon-list .icon-button {
    padding: 6px 0;
    border-radius: 8px;
    border: none;
    background: #f1f1f1;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
    .icon-list .icon-button:focus {
      outline: none; }
    .icon-list .icon-button:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .icon-list .icon-button.sw {
      color: #0FE367; }
      .icon-list .icon-button.sw.active {
        color: #FFF;
        background-color: #0FE367; }
    .icon-list .icon-button.erotic {
      color: #FFCC00; }
      .icon-list .icon-button.erotic.active {
        color: #FFF;
        background-color: #FFCC00; }
    .icon-list .icon-button.explicit {
      color: #FF9500; }
      .icon-list .icon-button.explicit.active {
        color: #FFF;
        background-color: #FF9500; }
    .icon-list .icon-button.extrem {
      color: #FF3636; }
      .icon-list .icon-button.extrem.active {
        color: #FFF;
        background-color: #FF3636; }

@media screen and (max-width: 720px) {
  .content-card-footer {
    flex-direction: column; } }

.content-history {
  padding: 20px;
  font-family: Arial, sans-serif; }
  .content-history__loading {
    text-align: center;
    font-size: 16px;
    color: #666; }
  .content-history__empty {
    text-align: center;
    font-size: 14px;
    color: #999; }
  .content-history__list {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px; }
  .content-history__item {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 15px;
    background: #f9f9f9; }
    .content-history__item:hover {
      background: #f4f4f4; }
    .content-history__item__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px; }
      .content-history__item__header .content-history__username {
        font-weight: bold;
        color: #007bff; }
      .content-history__item__header .content-history__action {
        font-size: 14px;
        color: #333;
        font-weight: bold; }
      .content-history__item__header .content-history__date {
        font-size: 12px;
        color: #666; }
    .content-history__item__details {
      font-size: 14px;
      color: #555; }
      .content-history__item__details p {
        margin: 5px 0; }
        .content-history__item__details p b {
          color: #333; }

@charset "UTF-8";
.content-rating-video {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  width: 100%; }
  .content-rating-video .content-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 8px;
    background: #fff; }
    .content-rating-video .content-card.active {
      box-shadow: 0 0 0px 3px #313fff; }
    .content-rating-video .content-card .info-button {
      background: rgba(35, 35, 35, 0.8);
      color: #FFF;
      border: none;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
      .content-rating-video .content-card .info-button:focus {
        outline: none; }
    .content-rating-video .content-card .content-card-footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 8px;
      align-items: center; }
      .content-rating-video .content-card .content-card-footer .footer-icon-list {
        width: 200px;
        justify-content: flex-end;
        display: flex; }
        .content-rating-video .content-card .content-card-footer .footer-icon-list .content-rating-button {
          color: #fff;
          border: none;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: default;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
          .content-rating-video .content-card .content-card-footer .footer-icon-list .content-rating-button.sw {
            background: #0FE367; }
          .content-rating-video .content-card .content-card-footer .footer-icon-list .content-rating-button.erotic {
            background: #FFCC00; }
          .content-rating-video .content-card .content-card-footer .footer-icon-list .content-rating-button.explicit {
            background: #FF9500; }
          .content-rating-video .content-card .content-card-footer .footer-icon-list .content-rating-button.extrem {
            background: #FF3636; }
    .content-rating-video .content-card .content-rating-video-title {
      font-size: 14px;
      font-weight: bold; }
    .content-rating-video .content-card .content-rating-video-duration {
      font-size: 12px;
      color: #505050; }
    .content-rating-video .content-card .content-container {
      display: flex; }
    .content-rating-video .content-card .content-container-video {
      display: flex;
      flex-direction: column;
      width: 100%; }
    .content-rating-video .content-card .video-container {
      width: 100%;
      max-height: 400px;
      /* Limite la hauteur maximale */
      overflow: hidden;
      /* Masque les débordements */
      display: flex;
      justify-content: center;
      align-items: center; }
    .content-rating-video .content-card .video-player video {
      max-height: 100%;
      /* Respecte la hauteur maximale du conteneur */
      width: auto;
      /* Garde les proportions */
      object-fit: contain;
      /* Centre la vidéo et ajuste pour ne pas déformer */ }
    .content-rating-video .content-card .images-container-scroll {
      height: 170px;
      background-color: #ddd;
      overflow-x: scroll;
      display: flex;
      grid-gap: 8px;
      gap: 8px; }
      .content-rating-video .content-card .images-container-scroll .image-container {
        width: 150px;
        height: 150px;
        background-color: #ddd;
        min-width: 150px;
        min-height: 150px; }
        .content-rating-video .content-card .images-container-scroll .image-container img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .content-rating-video .content-card .images-container {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 8px;
      gap: 8px;
      justify-content: center;
      flex: 1 1; }
      .content-rating-video .content-card .images-container .image-container {
        width: 150px;
        height: 150px;
        background-color: #ddd;
        position: relative; }
        .content-rating-video .content-card .images-container .image-container img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .content-rating-video .content-card .images-container .image-container .player-button {
          position: absolute;
          top: 50px;
          right: 50px;
          background: rgba(35, 35, 35, 0.8);
          color: #fff;
          border: none;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          font-size: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          z-index: 2; }
          .content-rating-video .content-card .images-container .image-container .player-button:focus {
            outline: none; }

.toggle-switch {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden; }
  .toggle-switch .toggle-button {
    padding: 8px 12px;
    background-color: #f5f5f5;
    color: #555;
    border: none;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .toggle-switch .toggle-button:focus {
      outline: none; }
    .toggle-switch .toggle-button.active {
      background-color: #007bff;
      color: #fff; }
    .toggle-switch .toggle-button:hover {
      background-color: #e0e0e0; }

.autocomplete-user {
  position: relative;
  width: 100%;
  font-family: Arial, sans-serif; }
  .autocomplete-user .input-container {
    display: flex;
    align-items: center;
    position: relative; }
    .autocomplete-user .input-container .autocomplete-input {
      width: 100%;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
      -webkit-transition: border 0.3s ease;
      transition: border 0.3s ease; }
      .autocomplete-user .input-container .autocomplete-input:focus {
        border-color: #007bff;
        outline: none; }
      .autocomplete-user .input-container .autocomplete-input:disabled {
        background-color: #f5f5f5;
        color: #aaa; }
    .autocomplete-user .input-container .reset-button {
      position: absolute;
      right: 5px;
      background: none;
      border: none;
      color: #888;
      font-size: 14px;
      cursor: pointer; }
      .autocomplete-user .input-container .reset-button:hover {
        color: #333; }
    .autocomplete-user .input-container .loading-spinner {
      position: absolute;
      right: 10px;
      font-size: 14px;
      color: #888; }
  .autocomplete-user .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    list-style: none;
    padding: 0; }
    .autocomplete-user .suggestions-list .suggestion-item {
      padding: 8px 12px;
      cursor: pointer; }
      .autocomplete-user .suggestions-list .suggestion-item:hover {
        background-color: #f0f0f0; }

#content-features .content-features-header {
  margin-bottom: 16px; }
  #content-features .content-features-header .content-features-header-filter {
    padding: 16px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    border-radius: 16px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px; }
    #content-features .content-features-header .content-features-header-filter .content-features-header-filter-separation {
      border: #e4e4e4 solid 0.5px; }
    #content-features .content-features-header .content-features-header-filter .filter-right {
      display: flex;
      justify-content: flex-end;
      flex: 1 1;
      align-items: center; }
      #content-features .content-features-header .content-features-header-filter .filter-right .filter-submit {
        background: #007bff;
        color: #FFF;
        border: 0;
        border-radius: 8px;
        padding: 8px 12px;
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s; }
        #content-features .content-features-header .content-features-header-filter .filter-right .filter-submit:hover {
          -webkit-transform: scale(1.02);
                  transform: scale(1.02);
          background: #2990ff; }
        #content-features .content-features-header .content-features-header-filter .filter-right .filter-submit:disabled {
          background: #dddddd; }
        #content-features .content-features-header .content-features-header-filter .filter-right .filter-submit:focus {
          outline: none; }
    #content-features .content-features-header .content-features-header-filter .content-features-header-filter-input-group {
      display: flex;
      grid-gap: 4px;
      gap: 4px;
      align-items: center; }
      #content-features .content-features-header .content-features-header-filter .content-features-header-filter-input-group .filter-select {
        padding: 8px 12px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        width: 200px; }
        #content-features .content-features-header .content-features-header-filter .content-features-header-filter-input-group .filter-select:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 4px rgba(0, 123, 255, 0.4); }

#content-features .content-features-footer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px; }
  #content-features .content-features-footer .content-submit {
    background: #007bff;
    color: #FFF;
    border: 0;
    border-radius: 8px;
    padding: 8px 12px;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
    #content-features .content-features-footer .content-submit:hover {
      -webkit-transform: scale(1.02);
              transform: scale(1.02);
      background: #2990ff; }
    #content-features .content-features-footer .content-submit:disabled {
      background: #dddddd; }

#content-moderation .content-moderation-header {
  margin-bottom: 16px; }
  #content-moderation .content-moderation-header .content-moderation-header-filter {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
    width: 100%; }
    #content-moderation .content-moderation-header .content-moderation-header-filter.card-filter {
      padding: 16px;
      background-color: #f9f9f9;
      border-bottom: 1px solid #ddd;
      border-radius: 16px;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 16px;
      gap: 16px; }
    #content-moderation .content-moderation-header .content-moderation-header-filter .content-moderation-header-filter-separation {
      border: #e4e4e4 solid 0.5px; }
    #content-moderation .content-moderation-header .content-moderation-header-filter .filter-right {
      display: flex;
      justify-content: flex-end;
      flex: 1 1;
      align-items: center; }
      #content-moderation .content-moderation-header .content-moderation-header-filter .filter-right .filter-submit {
        background: #007bff;
        color: #FFF;
        border: 0;
        border-radius: 8px;
        padding: 8px 12px;
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s; }
        #content-moderation .content-moderation-header .content-moderation-header-filter .filter-right .filter-submit:hover {
          -webkit-transform: scale(1.02);
                  transform: scale(1.02);
          background: #2990ff; }
        #content-moderation .content-moderation-header .content-moderation-header-filter .filter-right .filter-submit:disabled {
          background: #dddddd; }
        #content-moderation .content-moderation-header .content-moderation-header-filter .filter-right .filter-submit:focus {
          outline: none; }
    #content-moderation .content-moderation-header .content-moderation-header-filter .content-moderation-header-filter-input-group {
      display: flex;
      grid-gap: 4px;
      gap: 4px;
      align-items: center; }
      #content-moderation .content-moderation-header .content-moderation-header-filter .content-moderation-header-filter-input-group .filter-select {
        padding: 8px 12px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        width: 200px; }
        #content-moderation .content-moderation-header .content-moderation-header-filter .content-moderation-header-filter-input-group .filter-select:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 4px rgba(0, 123, 255, 0.4); }

#content-moderation .content-moderation-footer {
  display: flex;
  justify-content: center;
  margin: 20px 0px; }

/***** Gender Colors *****/
/***** Screen Size *****/
/***** Fonts *****/
/***** Styles ****/
/***** Size *****/
.loader {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 2px solid #c3c3c3;
  border-radius: 50%;
  -webkit-animation: spin 0.75s infinite linear;
          animation: spin 0.75s infinite linear; }
  .loader::before, .loader::after {
    left: -2px;
    top: -2px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit; }
  .loader.xsmall {
    width: 10px;
    height: 10px; }
  .loader.small {
    width: 20px;
    height: 20px; }
  .loader.big {
    width: 60px;
    height: 60px;
    margin: 64px auto; }

.spin-loader,
.spin-loader::before {
  display: block;
  border-color: transparent;
  border-top-color: #c3c3c3; }

.spin-loader::before {
  height: 100%;
  width: 100%;
  -webkit-animation: spin 1.5s infinite ease;
          animation: spin 1.5s infinite ease; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.infinite-loading-container {
  position: relative; }
  .infinite-loading-container .infinite-loading-bar {
    height: 4px;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background-color: #E9E9E9;
    border-radius: 2px; }
    .infinite-loading-container .infinite-loading-bar::before {
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 4px;
      background-color: #2276f4;
      -webkit-animation: infinite-loading-bar 2s linear infinite;
              animation: infinite-loading-bar 2s linear infinite; }

@-webkit-keyframes infinite-loading-bar {
  from {
    left: -200px;
    width: 20%; }
  90% {
    width: 70%; }
  80% {
    left: 80%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

@keyframes infinite-loading-bar {
  from {
    left: -200px;
    width: 20%; }
  90% {
    width: 70%; }
  80% {
    left: 80%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

.mk-editor .color-primary {
  color: #2276f4; }

.mk-editor .color-secondary {
  color: #808080; }

.mk-editor .color-success {
  color: #28a745; }

.mk-editor .color-danger {
  color: #fb4d4d; }

.mk-editor .color-warning {
  color: #ffb142; }

.mk-editor .color-info {
  color: #6ba3f8; }

.mk-editor .quoting-user {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  width: 100%;
  border-left: 10px solid #ccc;
  padding: 16px;
  border-radius: 4px; }
  .mk-editor .quoting-user .blockquote--classic {
    all: unset;
    border-left: 0;
    padding-left: 0;
    font-style: italic; }
  .mk-editor .quoting-user .quoting-user-username {
    margin-left: auto;
    font-size: 14px; }

.mk-editor .draft-survey {
  max-width: 500px;
  margin: 0 auto;
  padding: 16px;
  border: 1px solid #E9E9E9;
  border-radius: 4px; }
  .mk-editor .draft-survey .survey {
    border: 0;
    padding: 0;
    margin: 0; }

.chat-editor-container {
  display: flex;
  align-items: center;
  flex-grow: 1; }
  .chat-editor-container .emojiSelect {
    display: flex;
    align-items: flex-end; }
  .chat-editor-container .add-comment {
    overflow: hidden; }
  .chat-editor-container .mk-editor-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: #fff;
    padding: 0;
    margin: 0;
    cursor: pointer;
    line-height: 1.2em;
    height: 2em;
    width: 2em;
    margin: 0;
    border-radius: 100%; }
    .chat-editor-container .mk-editor-btn i {
      width: 16px; }
  .chat-editor-container .mk-editor {
    width: 100%;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    margin-left: auto; }
    .chat-editor-container .mk-editor.active {
      width: 150px;
      -webkit-transition: all .2s linear;
      transition: all .2s linear; }

.mk-editor {
  display: flex;
  flex-direction: column;
  border: 1px solid #E9E9E9;
  border-radius: 4px; }
  .mk-editor img, .mk-editor video {
    max-width: 100%;
    max-height: 1000px; }
  .mk-editor .draft-uploaded-media {
    width: 100%; }
    .mk-editor .draft-uploaded-media img, .mk-editor .draft-uploaded-media video {
      max-width: 100%;
      max-height: 1000px; }
  .mk-editor .draft-shared-media {
    display: flex;
    flex-direction: column;
    width: 250px;
    padding: 16px;
    text-decoration: none;
    border: 1px solid #E9E9E9;
    border-radius: 4px; }
    .mk-editor .draft-shared-media .content {
      overflow: hidden;
      max-width: 100%;
      max-height: 200px;
      min-height: 200px;
      margin-bottom: 8px;
      overflow: hidden;
      border-radius: 4px;
      background-color: #E9E9E9;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center; }
      .mk-editor .draft-shared-media .content img {
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto; }
    .mk-editor .draft-shared-media .caption {
      border-top: 1px solid #E9E9E9;
      padding-top: 8px;
      font-weight: 600;
      color: #333333; }
    .mk-editor .draft-shared-media.article .content, .mk-editor .draft-shared-media.album .content {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      flex-grow: 1; }
  .mk-editor .link {
    color: #2276f4; }
  .mk-editor span[data-text] {
    font-family: "Open Sans", sans-serif; }
  .mk-editor .emojiSelectPopoverGroups {
    width: unset; }
  .mk-editor .emojiSelectPopoverGroupItem {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 7); }
  .mk-editor .mk-editor-toolbar {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid #E9E9E9;
    padding: 8px 0px; }
    .mk-editor .mk-editor-toolbar .mk-editor-btn-group {
      padding: 8px 16px;
      border-right: 1px solid #E9E9E9;
      align-items: center;
      display: flex; }
      .mk-editor .mk-editor-toolbar .mk-editor-btn-group:last-child {
        margin-right: 0;
        border-right: 0; }
    .mk-editor .mk-editor-toolbar .mk-editor-btn {
      color: #808080;
      cursor: pointer;
      margin-right: 16px;
      display: inline-block;
      padding: 4px 8px;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .mk-editor .mk-editor-toolbar .mk-editor-btn.dropdown-toggle .icon-arrow-down {
        margin-left: 4px;
        -webkit-transition: -webkit-transform .1s linear;
        transition: -webkit-transform .1s linear;
        transition: transform .1s linear;
        transition: transform .1s linear, -webkit-transform .1s linear; }
      .mk-editor .mk-editor-toolbar .mk-editor-btn.dropdown-toggle.active .icon-arrow-down {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        fill: #2276f4; }
      .mk-editor .mk-editor-toolbar .mk-editor-btn.active {
        color: #2276f4; }
      .mk-editor .mk-editor-toolbar .mk-editor-btn:last-child {
        margin-right: 0; }
    .mk-editor .mk-editor-toolbar .dropdown-menu {
      position: absolute;
      border: 1px solid #E9E9E9;
      border-radius: 4px;
      background-color: #FFF;
      z-index: 2;
      top: 50px; }
      .mk-editor .mk-editor-toolbar .dropdown-menu .dropdown-item {
        display: block;
        padding: 8px;
        color: #333333; }
        .mk-editor .mk-editor-toolbar .dropdown-menu .dropdown-item h1 {
          font-size: 2em; }
        .mk-editor .mk-editor-toolbar .dropdown-menu .dropdown-item h2 {
          font-size: 1.5em; }
        .mk-editor .mk-editor-toolbar .dropdown-menu .dropdown-item h3 {
          font-size: 1.17em; }
        .mk-editor .mk-editor-toolbar .dropdown-menu .dropdown-item h4 {
          font-size: 1em; }
        .mk-editor .mk-editor-toolbar .dropdown-menu .dropdown-item h5 {
          font-size: 0.83em; }
        .mk-editor .mk-editor-toolbar .dropdown-menu .dropdown-item h6 {
          font-size: 0.67em; }
  .mk-editor .DraftEditor-root .public-DraftEditorPlaceholder-root {
    padding: 8px; }
  .mk-editor .DraftEditor-root .public-DraftEditor-content {
    max-height: 500px;
    overflow: auto;
    min-height: 300px;
    padding: 8px; }
    .mk-editor .DraftEditor-root .public-DraftEditor-content h1 {
      font-size: 2em; }
    .mk-editor .DraftEditor-root .public-DraftEditor-content h2 {
      font-size: 1.5em; }
    .mk-editor .DraftEditor-root .public-DraftEditor-content h3 {
      font-size: 1.17em; }
    .mk-editor .DraftEditor-root .public-DraftEditor-content h4 {
      font-size: 1em; }
    .mk-editor .DraftEditor-root .public-DraftEditor-content h5 {
      font-size: 0.83em; }
    .mk-editor .DraftEditor-root .public-DraftEditor-content h6 {
      font-size: 0.67em; }
    .mk-editor .DraftEditor-root .public-DraftEditor-content div[data-contents] {
      line-height: 1.8;
      color: #333333;
      font-size: 16px;
      white-space: pre-wrap;
      font-family: "Open Sans", sans-serif; }
  .mk-editor.simple-display {
    display: flex;
    flex-direction: row;
    width: 100%; }
    .mk-editor.simple-display .DraftEditor-root {
      width: 100%; }
      .mk-editor.simple-display .DraftEditor-root .public-DraftEditor-content {
        min-height: unset;
        width: 100%;
        height: 36px;
        overflow: auto; }
    .mk-editor.simple-display.edit-comment--draft {
      position: relative; }
      .mk-editor.simple-display.edit-comment--draft .DraftEditor-root .public-DraftEditor-content {
        min-height: unset;
        width: 100%;
        height: 150px;
        overflow: auto; }
      .mk-editor.simple-display.edit-comment--draft .emojiSelect {
        display: flex;
        align-items: flex-end;
        margin: 8px 8px 8px 0; }
        .mk-editor.simple-display.edit-comment--draft .emojiSelect .emojiSelect, .mk-editor.simple-display.edit-comment--draft .emojiSelect .emojiSelectPopover {
          display: flex;
          flex-direction: column;
          right: 0;
          bottom: 0;
          height: 100%; }
          .mk-editor.simple-display.edit-comment--draft .emojiSelect .emojiSelect .emojiSelectPopoverGroups, .mk-editor.simple-display.edit-comment--draft .emojiSelect .emojiSelectPopover .emojiSelectPopoverGroups {
            height: unset;
            flex: 1 1; }
    .mk-editor.simple-display.add-comment {
      border-radius: 25px;
      padding: 0px;
      overflow: hidden; }
      .mk-editor.simple-display.add-comment .emojiSelect {
        display: flex;
        align-items: flex-end; }
        .mk-editor.simple-display.add-comment .emojiSelect .emojiSelectButton, .mk-editor.simple-display.add-comment .emojiSelect .emojiSelectButtonPressed {
          border: 0;
          width: 100%; }
      .mk-editor.simple-display.add-comment .emojiSelectPopover {
        margin-top: -40px;
        box-shadow: unset;
        right: 0; }
      .mk-editor.simple-display.add-comment .DraftEditor-root {
        padding: 0px;
        overflow: hidden;
        display: flex;
        align-items: center; }
        .mk-editor.simple-display.add-comment .DraftEditor-root .public-DraftEditorPlaceholder-root {
          padding: 8px;
          line-height: 1.3; }
        .mk-editor.simple-display.add-comment .DraftEditor-root .DraftEditor-editorContainer {
          width: 100%;
          padding: 8px; }
        .mk-editor.simple-display.add-comment .DraftEditor-root .public-DraftEditor-content {
          padding: 0;
          height: 100%;
          max-height: 72px; }
          .mk-editor.simple-display.add-comment .DraftEditor-root .public-DraftEditor-content div[data-contents] {
            line-height: 1.3; }
    .mk-editor.simple-display.add-status {
      border: 0;
      padding: 0 16px; }
      .mk-editor.simple-display.add-status .DraftEditor-root .public-DraftEditor-content {
        min-height: unset;
        height: 90px;
        padding: 0; }
      .mk-editor.simple-display.add-status .DraftEditor-root .public-DraftEditorPlaceholder-root {
        padding: 0; }
  .mk-editor.waiting-draft {
    position: relative; }
    .mk-editor.waiting-draft .fake-answer-topic {
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 4px;
      border: 1px solid #E9E9E9;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center; }

.readonly-editor img, .readonly-editor video {
  max-width: 100%;
  max-height: 1000px; }

.readonly-editor .draft-uploaded-media {
  width: 100%; }
  .readonly-editor .draft-uploaded-media img, .readonly-editor .draft-uploaded-media video {
    max-width: 100%;
    max-height: 1000px; }

.readonly-editor .quoting-user {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  width: 100%;
  border-left: 10px solid #ccc;
  padding: 16px;
  border-radius: 4px; }
  .readonly-editor .quoting-user .blockquote--classic {
    all: unset;
    border-left: 0;
    padding-left: 0;
    font-style: italic; }
  .readonly-editor .quoting-user .quoting-user-username {
    margin-left: auto;
    font-size: 14px; }

.readonly-editor .draft-shared-media {
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 16px;
  text-decoration: none;
  border: 1px solid #E9E9E9;
  border-radius: 4px; }
  .readonly-editor .draft-shared-media .content {
    overflow: hidden;
    max-width: 100%;
    max-height: 200px;
    min-height: 200px;
    margin-bottom: 8px;
    overflow: hidden;
    border-radius: 4px;
    background-color: #E9E9E9;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .readonly-editor .draft-shared-media .content img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto; }
  .readonly-editor .draft-shared-media .caption {
    border-top: 1px solid #E9E9E9;
    padding-top: 8px;
    font-weight: 600;
    color: #333333; }
  .readonly-editor .draft-shared-media.article .content, .readonly-editor .draft-shared-media.album .content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-grow: 1; }
  .readonly-editor .draft-shared-media.error {
    font-size: 14px;
    color: #808080;
    text-align: center; }

.readonly-editor .link {
  color: #2276f4; }

.readonly-editor .mentionned-name {
  color: inherit; }

.readonly-editor span[data-text] {
  font-family: "Open Sans", sans-serif; }

.readonly-editor .emojiSelectPopoverGroups {
  width: unset; }

.readonly-editor .emojiSelectPopoverGroupItem {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 7); }

.readonly-editor .DraftEditor-root .public-DraftEditor-content {
  max-height: unset;
  min-height: unset; }
  .readonly-editor .DraftEditor-root .public-DraftEditor-content h1 {
    font-size: 2em; }
  .readonly-editor .DraftEditor-root .public-DraftEditor-content h2 {
    font-size: 1.5em; }
  .readonly-editor .DraftEditor-root .public-DraftEditor-content h3 {
    font-size: 1.17em; }
  .readonly-editor .DraftEditor-root .public-DraftEditor-content h4 {
    font-size: 1em; }
  .readonly-editor .DraftEditor-root .public-DraftEditor-content h5 {
    font-size: 0.83em; }
  .readonly-editor .DraftEditor-root .public-DraftEditor-content h6 {
    font-size: 0.67em; }

.mentionSuggestions {
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  overflow: auto; }
  .mentionSuggestions .mentionSuggestionsEntryContainer {
    display: flex;
    align-items: center; }
  .mentionSuggestions .mentionSuggestionsEntryContainerRight {
    width: 100%;
    padding-left: 8px; }
  .mentionSuggestions .mentionSuggestionsEntry, .mentionSuggestions .mentionSuggestionsEntryFocused {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px; }
    .mentionSuggestions .mentionSuggestionsEntry:active, .mentionSuggestions .mentionSuggestionsEntryFocused:active {
      background-color: #E9E9E9; }
  .mentionSuggestions .mentionSuggestionsEntryFocused {
    background-color: #E9E9E9; }
  .mentionSuggestions .mentionSuggestionsEntryText,
  .mentionSuggestions .mentionSuggestionsEntryTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3; }
  .mentionSuggestions .mentionSuggestionsEntryTitle {
    color: #333333; }
  .mentionSuggestions .avatar {
    width: 30px;
    height: 30px; }

.mentionned-name {
  color: #2276f4;
  font-weight: 600; }

.emojiSelect {
  background: #FFF;
  display: inline-block;
  padding: 4px 8px; }

.emojiSelectButton, .emojiSelectButtonPressed {
  margin: 0;
  box-sizing: border-box;
  border-radius: 100%;
  line-height: 1.2em;
  font-size: 1.5em;
  height: 32px;
  width: 32px;
  color: #888;
  border: 0;
  cursor: pointer;
  background-color: transparent; }

.emojiSelectButton:focus, .emojiSelectButtonPressed:focus {
  outline: 0;
  /* reset for :focus */ }

.emojiSelectButton:hover, .emojiSelectButtonPressed:hover {
  background: #f3f3f3; }

.emojiSelectButton:active, .emojiSelectButtonPressed:active {
  background: #e6e6e6; }

.emojiSelectButtonPressed {
  background: #ededed; }

.emojiSelectPopover {
  margin-top: 10px;
  padding: 0 .3em;
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.21); }

.emojiSelectPopoverClosed {
  display: none; }

.emojiSelectPopoverTitle {
  margin: 0 0 .3em;
  padding-left: 1em;
  height: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
  display: none; }

.emojiSelectPopoverGroups {
  margin: 0 0 .3em;
  position: relative;
  z-index: 0;
  width: 21em;
  height: 20em; }

.emojiSelectPopoverGroup {
  padding: 0 .5em; }

.emojiSelectPopoverGroup:first-child .emojiSelectPopoverGroupTitle {
  display: none; }

.emojiSelectPopoverGroupTitle {
  margin: 1em 0;
  padding-left: .5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
  display: none; }

.emojiSelectPopoverGroupList {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap; }

.emojiSelectPopoverGroupItem {
  display: flex;
  justify-content: center;
  align-items: center; }

.emojiSelectPopoverToneSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2; }

.emojiSelectPopoverToneSelectList {
  margin: .3em;
  padding: .3em;
  position: absolute;
  display: flex;
  list-style: none;
  border: 1px solid #e0e0e0;
  border-radius: .5em;
  background: #fff;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1); }

.emojiSelectPopoverToneSelectItem {
  width: 2.5em;
  height: 2.5em; }

.emojiSelectPopoverToneSelectItem:first-child {
  border-right: 1px solid #e0e0e0; }

.emojiSelectPopoverEntry, .emojiSelectPopoverEntryFocused {
  padding: 0;
  background: none;
  border: none;
  outline: none;
  font-size: 1.5em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  -webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  cursor: pointer; }

.emojiSelectPopoverEntryFocused {
  background-color: #efefef; }

.emojiSelectPopoverEntryIcon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle; }

.emojiSelectPopoverNav {
  margin: 0;
  padding: 0 .5em;
  display: flex;
  width: 20em;
  list-style: none; }

.emojiSelectPopoverNavItem {
  width: 2.5em;
  height: 2.5em; }

.emojiSelectPopoverNavEntry, .emojiSelectPopoverNavEntryActive {
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  color: #bdbdbd;
  background: none;
  border: none;
  outline: none; }

.emojiSelectPopoverNavEntryActive {
  color: #42a5f5; }

.emojiSelectPopoverScrollbar {
  position: absolute;
  right: 0;
  top: .3em;
  bottom: .3em;
  width: .25em;
  background-color: #e0e0e0;
  border-radius: .125em;
  opacity: .1;
  -webkit-transition: opacity .4s;
  transition: opacity .4s; }

.emojiSelectPopoverScrollbarThumb {
  background-color: #000;
  border-radius: .125em;
  cursor: pointer; }

.emojiSelectPopoverGroups:hover .emojiSelectPopoverScrollbar {
  opacity: .3; }

.emojiSelectPopoverGroups .emojiSelectPopoverScrollbar:hover,
.emojiSelectPopoverGroups .emojiSelectPopoverScrollbar:active {
  opacity: .6; }

.emoji {
  background-position: center;
  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-size: contain;
  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;
  /*
  We need to limit the emoji width because it can be multiple characters
  long if it is a 32bit unicode. Since the proper width depends on the font and
  it's relationship between 0 and other characters it's not ideal. 1.95ch is not
  the best value, but hopefully a good enough approximation for most fonts.
  */
  display: inline-block;
  overflow: hidden;
  max-width: 1.95ch;
  /*
  Needed for iOS rendering to avoid some icons using a lot of height without
  actually needing it.
  */
  max-height: 1em;
  line-height: inherit;
  margin: -.2ex 0em .2ex;
  /*
  In the past we used opacity: 0 to hide the original Emoji icon no matter what
  system it is. Recently we switched to color: transparent since it started to
  work in recent iOS version.
  */
  color: transparent;
  /*
  Some SVG files (say 2764 for :heart:) don't have default width/height, thus
  may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
  Chrome 61).
  */
  min-width: 1em; }

.emojiSuggestionsEntry {
  padding: 5px 10px 1px 10px;
  -webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56); }

.emojiSuggestionsEntry:active {
  background-color: #cce7ff; }

.emojiSuggestionsEntryFocused {
  background-color: #e6f3ff; }

.emojiSuggestionsEntryText {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em; }

.emojiSuggestionsEntryIcon {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: inline-block; }

.emojiSuggestions {
  border: 1px solid #eee;
  margin-top: 1.75em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px gainsboro;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transform: scale(0); }

.addItem {
  background: #FFF;
  display: inline-block;
  padding: 4px 8px; }
  .addItem .menu-item {
    display: flex;
    align-items: center;
    padding: 8px;
    color: #333333;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    -webkit-transition: all .1s linear;
    transition: all .1s linear; }
    .addItem .menu-item i {
      width: 14px;
      margin-right: 16px;
      fill: #b6b6b6;
      -webkit-transition: all .1s linear;
      transition: all .1s linear; }
  .addItem .menu-item-color {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 0 6px;
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .addItem .menu-item-color.color-primary {
      background: #2276f4; }
    .addItem .menu-item-color.color-secondary {
      background: #808080; }
    .addItem .menu-item-color.color-success {
      background: #28a745; }
    .addItem .menu-item-color.color-danger {
      background: #fb4d4d; }
    .addItem .menu-item-color.color-warning {
      background: #ffb142; }
    .addItem .menu-item-color.color-info {
      background: #6ba3f8; }
    .addItem .menu-item-color:hover.color-primary, .addItem .menu-item-color.active.color-primary {
      border-color: #0848a8; }
    .addItem .menu-item-color:hover.color-secondary, .addItem .menu-item-color.active.color-secondary {
      border-color: #4d4d4d; }
    .addItem .menu-item-color:hover.color-success, .addItem .menu-item-color.active.color-success {
      border-color: #145523; }
    .addItem .menu-item-color:hover.color-danger, .addItem .menu-item-color.active.color-danger {
      border-color: #dd0505; }
    .addItem .menu-item-color:hover.color-warning, .addItem .menu-item-color.active.color-warning {
      border-color: #db8100; }
    .addItem .menu-item-color:hover.color-info, .addItem .menu-item-color.active.color-info {
      border-color: #0848a8; }
  .addItem .menu-item-color__active {
    height: 0;
    width: 0;
    margin: auto;
    position: absolute;
    border-radius: 100%;
    opacity: .5;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto; }
    .addItem .menu-item-color__active:hover, .addItem .menu-item-color__active.active {
      height: 24px;
      width: 24px; }
  .addItem .addItemPopover {
    margin-top: 10px;
    background: #FFF;
    position: absolute;
    width: 300px;
    border-radius: 2px;
    padding: 10px;
    box-shadow: 0px 4px 30px 0px gainsboro;
    z-index: 1000; }
    .addItem .addItemPopover.colorPopover {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: auto;
      max-width: 300px; }
  .addItem .addItemClosedPopover {
    display: none; }
  .addItem .addItemButton, .addItem .addItemPressedButton, .addItem .addItem.active {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: #fff;
    padding: 0;
    color: #888;
    margin: 0;
    cursor: pointer;
    line-height: 1.2em;
    height: 2em;
    width: 2em;
    margin: 0;
    border-radius: 100%; }
    .addItem .addItemButton i, .addItem .addItemPressedButton i, .addItem .addItem.active i {
      width: 16px; }
    .addItem .addItemButton.addItemButton:focus, .addItem .addItemPressedButton:focus, .addItem .addItem.active:focus {
      outline: 0;
      /* reset for :focus */ }
    .addItem .addItemButton:hover, .addItem .addItemPressedButton:hover, .addItem .addItem.active:hover {
      background: #f3f3f3; }
    .addItem .addItemButton:active, .addItem .addItemPressedButton:active, .addItem .addItem.active:active {
      background: #f3f3f3; }
    .addItem .addItemButton.active, .addItem .active.addItemPressedButton, .addItem .active.addItem {
      background-color: #f3f3f3; }
    .addItem .addItemButton.color-primary i, .addItem .color-primary.addItemPressedButton i, .addItem .color-primary.addItem.active i {
      fill: #2276f4; }
    .addItem .addItemButton.color-secondary i, .addItem .color-secondary.addItemPressedButton i, .addItem .color-secondary.addItem.active i {
      fill: #808080; }
    .addItem .addItemButton.color-success i, .addItem .color-success.addItemPressedButton i, .addItem .color-success.addItem.active i {
      fill: #28a745; }
    .addItem .addItemButton.color-danger i, .addItem .color-danger.addItemPressedButton i, .addItem .color-danger.addItem.active i {
      fill: #fb4d4d; }
    .addItem .addItemButton.color-warning i, .addItem .color-warning.addItemPressedButton i, .addItem .color-warning.addItem.active i {
      fill: #ffb142; }
    .addItem .addItemButton.color-info i, .addItem .color-info.addItemPressedButton i, .addItem .color-info.addItem.active i {
      fill: #6ba3f8; }
    .addItem .addItemButton .selectedColor, .addItem .addItemPressedButton .selectedColor, .addItem .addItem.active .selectedColor {
      height: 2px;
      width: 100%;
      background-color: #000; }
  .addItem .addItemPressedButton, .addItem .addItem.active {
    background: #f3f3f3; }
  .addItem .addItemBottomGradient {
    width: 100%;
    height: 1em;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    pointer-events: none;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%); }
  .addItem .addItemInput {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 4px;
    width: 78%;
    border-radius: 2px;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe; }
  .addItem .addItemConfirmButton {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0;
    color: #888;
    margin: 0;
    border-radius: 2.1em;
    cursor: pointer;
    height: 2.1em;
    width: 18%;
    font-size: 1em;
    line-height: 2.1em;
    margin: 0;
    margin-left: 4%; }
    .addItem .addItemConfirmButton.addItemConfirmButton:focus {
      outline: 0;
      /* reset for :focus */ }
    .addItem .addItemConfirmButton.addItemConfirmButton:hover {
      background: #f3f3f3; }
    .addItem .addItemConfirmButton.addItemConfirmButton:active {
      background: #e6e6e6; }
  .addItem.chat-add-img {
    all: unset;
    display: flex; }
    .addItem.chat-add-img .menu-item i {
      margin-right: 0; }

#warning {
  max-width: 800px; }
  #warning .public-DraftEditor-content {
    max-height: 200px;
    min-height: 200px; }

#facette-form .infos {
  display: flex;
  flex-wrap: wrap; }
  #facette-form .infos .title {
    display: flex;
    justify-content: center;
    font-size: .9rem;
    background-color: rgba(51, 51, 51, 0.1);
    padding: 0.25rem 0.5rem;
    border-radius: 3px; }
  #facette-form .infos .text-info {
    color: #2276f4; }
  #facette-form .infos .text-danger {
    color: #dd0505; }
  #facette-form .infos .text-warning {
    color: #db8100; }
  #facette-form .infos .text-underlined {
    text-decoration: underline; }

#facette-categories .list-group .list-group-item {
  font-size: 0px; }

#facette-categories .list-group .cursor-pointer {
  font-size: 16px;
  position: relative; }

#facette-categories .list-group .list-group .cursor-pointer::before {
  content: "";
  border-top: solid 1px black;
  width: 17px;
  height: 1px;
  display: block;
  position: absolute;
  left: -20px;
  top: 12px; }

#facette-categories .list-group [role="tab"] [role="tab"] {
  border-left: solid 2px black; }

#facette-categories .list-group-item {
  border-top: none !important;
  border-bottom: none !important;
  padding: 2px 0 2px 16px; }

#facette-categories .list-group [type="button"] {
  margin-top: 1px !important;
  font-size: 10px;
  padding: 1px 6px; }

#keywords-warnings .warning-text {
  width: 500px; }

#keywords-warnings .actions {
  width: 160px;
  display: grid;
  grid-template-columns: 50% 50%; }

#keywords-warnings .action {
  margin-bottom: 5px; }

#keywords-warnings .keyword-warning {
  font-weight: bold;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  background: yellow; }

#keywords-warnings .content-remove {
  text-decoration: line-through; }

#keywords-warnings .ellipsis {
  opacity: .8;
  cursor: pointer;
  /* pour les vieux navigateurs */ }

#keywords-warnings .ellipsis:not(.visible) {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

#keywords-warnings .ellipsis:not(.visible)::before {
  content: '[...]'; }

#keywords-warnings .ellipsis.visible {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

#members .risk {
  background-color: #dc3545 !important;
  color: #FFFFFF; }
  #members .risk a {
    color: #FFFFFF; }
  #members .risk .btn-outline-primary {
    border-color: #FFFFFF; }

#members .panic-mode {
  background-color: #ffc107 !important; }
  #members .panic-mode .btn-outline-success {
    border-color: #4A4A4A !important; }

#members .action-panic {
  min-width: 100px; }

#members .legends {
  margin-bottom: 16px; }
  #members .legends .legend-members {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex; }
    #members .legends .legend-members li {
      margin: 0 8px;
      padding: 4px; }

#status .status-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  #status .status-list .status-list-item {
    width: 48%;
    border: #6F6F6F 1px solid;
    margin: 1%;
    padding: 8px; }
    #status .status-list .status-list-item .status-list-item-informations {
      display: flex;
      justify-content: space-between;
      border-bottom: #9e9e9e 0.5px solid; }
    #status .status-list .status-list-item .status-list-item-content {
      padding-top: 8px; }

@media only screen and (max-width: 720px) {
  #status .status-list .status-list-item {
    width: 100%;
    margin: 0 0 16px 0; }
    #status .status-list .status-list-item .status-list-item-informations {
      flex-direction: column;
      padding-bottom: 8px; } }

#banned-members .last-domain {
  list-style: none;
  display: flex;
  max-width: 100%;
  overflow: auto; }
  #banned-members .last-domain li {
    padding: 8px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content; }

#banned-members .banned-members-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  #banned-members .banned-members-list .banned-members-list-item {
    width: 48%;
    border: #6F6F6F 1px solid;
    margin: 1%;
    padding: 8px; }
    #banned-members .banned-members-list .banned-members-list-item .banned-members-list-item-informations {
      display: flex;
      justify-content: space-between;
      border-bottom: #9e9e9e 0.5px solid; }
    #banned-members .banned-members-list .banned-members-list-item .banned-members-list-item-content {
      padding-top: 8px; }

@media only screen and (max-width: 720px) {
  #banned-members .banned-members-list .banned-members-list-item {
    width: 100%;
    margin: 0 0 16px 0; }
    #banned-members .banned-members-list .banned-members-list-item .banned-members-list-item-informations {
      flex-direction: column;
      padding-bottom: 8px; } }

#member-description {
  margin-top: 20px; }
  #member-description .warning-text {
    width: 500px; }
  #member-description .actions {
    width: 160px;
    display: grid;
    grid-template-columns: 50% 50%; }
  #member-description .action {
    margin-bottom: 5px; }
  #member-description .keyword-warning {
    font-weight: bold;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    background: yellow; }
  #member-description .content-remove {
    text-decoration: line-through; }
  #member-description .ellipsis {
    opacity: .8;
    cursor: pointer;
    /* pour les vieux navigateurs */ }
  #member-description .ellipsis:not(.visible) {
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }
  #member-description .ellipsis:not(.visible)::before {
    content: '[...]'; }
  #member-description .ellipsis.visible {
    cursor: -webkit-zoom-out;
    cursor: zoom-out; }

.nav-link {
  padding: 0.5rem 0.6rem; }

.cursor-pointer {
  cursor: pointer; }

.nocursor-pointer {
  cursor: auto !important; }

.icone-xsmall {
  font-size: 0.60em; }

.checkbox-align-center {
  margin: 0;
  display: flex;
  align-items: center; }

.flex {
  display: flex; }

.f-d-r {
  flex-direction: row; }

.btn-loading {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.text-center {
  text-align: center; }

.font-size-14 {
  font-size: 14px; }

.img-min {
  min-width: 100%;
  min-height: 90px; }

.fit-content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.position-init {
  position: static !important;
  position: initial !important; }

.flex-grow-init {
  flex-grow: inherit; }

.img-preview-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden; }
  .img-preview-container .img-preview {
    max-width: 100%;
    max-height: 400px; }

.img-thumbnail {
  min-height: 180px;
  min-width: 180px; }

.man {
  color: #2276f4; }

.woman {
  color: #FF69B4; }

.couple {
  color: #713b87; }

.couple-lesbian {
  color: red; }

.couple-gay {
  color: green; }

.trans {
  color: blue; }

.break-long-text, .mk-editor .draft-shared-media .caption, .mk-editor span[data-text], .readonly-editor .draft-shared-media .caption, .readonly-editor span[data-text] {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }

.text-barred {
  text-decoration: line-through; }

.text-italic {
  font-style: italic; }

.min-w-120 {
  min-width: 120px; }

.avatar-max-80 {
  max-width: 80px; }
  .avatar-max-80 img {
    max-width: 80px; }

.avatar-max-40 {
  max-width: 40px; }
  .avatar-max-40 img {
    max-width: 40px; }

.big-thumb {
  max-width: 500px;
  min-width: 500px;
  display: flex;
  margin: auto; }

#categorizations {
  max-width: 100%;
  margin: auto;
  padding: 16px;
  margin-bottom: 40px; }

.content-thumb-like {
  max-width: 222px;
  margin: 8px; }
  .content-thumb-like .thumb-like {
    width: 222px;
    height: 222px; }
    .content-thumb-like .thumb-like .icone {
      width: 100%;
      font-size: 60px; }
    .content-thumb-like .thumb-like .like-accepted {
      background-color: rgba(11, 156, 49, 0.6);
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center; }
    .content-thumb-like .thumb-like .like-declined {
      background-color: rgba(255, 0, 0, 0.6);
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center; }
    .content-thumb-like .thumb-like .like {
      width: 50%;
      display: flex;
      align-items: center;
      content-visibility: hidden; }
    .content-thumb-like .thumb-like .like:hover {
      color: green;
      content-visibility: visible;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer; }
    .content-thumb-like .thumb-like .nolike {
      display: flex;
      align-items: center;
      content-visibility: hidden;
      width: 50%; }
    .content-thumb-like .thumb-like .nolike:hover {
      color: red;
      content-visibility: visible;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer; }

.o-s-y-800 {
  overflow-y: scroll;
  height: 800px; }

.f-s-11 {
  font-size: 11px; }

.f-w-500 {
  font-weight: 500; }

.m-w-550 {
  width: 550px;
  max-width: 550px; }

.warning {
  border-color: orange;
  border-style: dotted; }

.modal-big-thumb .modal-dialog {
  max-width: 90%; }
  .modal-big-thumb .modal-dialog img {
    max-width: 100%;
    max-height: 80vh; }

.modal-big {
  width: "50%";
  left: "25%"; }

.f-a-c {
  display: flex;
  align-items: center; }

.rooms-list-users {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0; }
  .rooms-list-users li {
    padding-right: 4px; }

.room-message-deleted {
  color: red; }

.product-disabled {
  background: #ee5379; }

@media screen and (max-width: 720px) {
  .modal-big {
    width: "100%" !important;
    left: "0"; } }

