@import 'variables';

//components
@import 'components/_loader';
@import 'components/editor/_article-style';
@import 'components/editor/chat-editor';
@import 'components/editor/mk-editor';
@import 'components/editor/read-only-editor';
@import 'components/editor/mention-suggestions';
@import 'components/editor/emoji';
@import 'components/editor/add-item';

@import 'formulaires/_warning';
@import 'formulaires/_facette';

@import 'page/facette';
@import 'page/keywordsWarnings';
@import 'page/members';
@import 'page/contents/status';
@import 'page/moderations/bannedMembers';
@import 'page/moderations/memberDescription';

.nav-link{
  padding: 0.5rem 0.6rem;
}

.cursor-pointer{
  cursor: pointer;
}

.nocursor-pointer{
  cursor: auto !important;
}

.icone-xsmall{
  font-size: 0.60em;
}

.checkbox-align-center{
  margin: 0;
  display: flex;
  align-items: center;
}

.flex{
  display: flex;
}

.f-d-r{
  flex-direction: row;
}

.btn-loading{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.text-center{
  text-align: center;
}

.font-size-14{
  font-size: 14px;
}

.img-min{
  min-width: 100%;
  min-height: 90px;
}

.fit-content{
  height: fit-content;
}

.position-init{
  position: initial !important;
}
.flex-grow-init{
flex-grow: inherit;
}

.img-preview-container{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .img-preview{
    max-width: 100%;
    max-height: 400px;
  }
}

.img-thumbnail{
  min-height: 180px;
  min-width: 180px;
}


.man {
  color: #2276f4;
}

.woman {
  color:#FF69B4;
}

.couple {
  color: darken(#9b59b6, 15%);
}

.couple-lesbian {
  color: red;
}

.couple-gay {
  color: green;
}

.trans {
  color: blue;
}


.break-long-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.text-barred{
  text-decoration: line-through;
}
.text-italic{
  font-style: italic;
}

.min-w-120{
  min-width: 120px;
}
.avatar-max-80{
  max-width: 80px;
  img{
    max-width: 80px;
  }
}
.avatar-max-40{
  max-width: 40px;
  img{
    max-width: 40px;
  }
}

.big-thumb{
  max-width: 500px;
  min-width: 500px;
  display: flex;
  margin: auto;
}

#categorizations{
  max-width: 100%;
  margin: auto;
  padding: 16px;
  margin-bottom: 40px;
}

.content-thumb-like{
  max-width: 222px;
  margin: 8px;

  .thumb-like{
    width: 222px;
    height: 222px;
    .icone{
      width: 100%;
      font-size: 60px;
    }

    .like-accepted{
      background-color:rgba(11, 156, 49, 0.6);
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .like-declined{
      background-color:rgba(255, 0, 0, 0.6);
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .like{
      width: 50%;
      display: flex;
      align-items: center;
      content-visibility: hidden;
    }
    .like:hover{
      color: green;
      content-visibility: visible;
      background-color:rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
    .nolike{
      display: flex;
      align-items: center;
      content-visibility: hidden;
      width: 50%;
    }
    .nolike:hover{
      color: red;
      content-visibility: visible;
      background-color:rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }
}

.o-s-y-800{
  overflow-y: scroll;
  height: 800px;
}

.f-s-11{
  font-size: 11px;
}
.f-w-500{
  font-weight: 500;
}

.m-w-550{
  width: 550px;
  max-width: 550px;
}

.warning{
  border-color: orange;
  border-style: dotted;
}


.modal-big-thumb{
  .modal-dialog{
    max-width: 90%;
    img{
      max-width: 100%;
      max-height: 80vh;
    }
  }
}

.modal-big{
  width : "50%";
  left : "25%";
}

.f-a-c{
  display: flex;
  align-items: center;
}

.rooms-list-users{
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  li{
    padding-right: 4px;
  }
}

.room-message-deleted{
  color: red;
}

.product-disabled{
  background: #ee5379;
}

@media screen and (max-width: 720px) { 
  .modal-big{
    width : "100%" !important;
    left : "0";
  }
  
}