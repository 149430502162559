.toggle-switch {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  
    .toggle-button {
      padding: 8px 12px;
      background-color: #f5f5f5;
      color: #555;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease;
      &:focus{
        outline: none;
      }
      &.active {
        background-color: #007bff;
        color: #fff;
      }
  
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }